<template>
  <div>
    <header
      :class="['ad-header-container']"
      :style="{
        height: height,
        'background-image': `url(${getBg()})`,
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      }"
    >
      <div class="banner-container">
        <slot></slot>
      </div>
    </header>
    <header :class="['ad-header-container-mobile']">
      <img :src="getBg()" style="width: 100%" />
      <div class="banner-container">
        <slot></slot>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "AdHeaderBaseComponent",
  props: {
    height: {
      type: String,
      default: "780px",
    },
    mobileHeight: {
      type: String,
      default: "667px",
    },
    isLocal: {
      type: Boolean,
      default: true,
    },
    bg: {
      type: String,
      default: "banner0.jpg",
    },
    mobileBg: {
      type: String,
      default: "banner.png",
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  created() {
    this.width = window.innerWidth;
  },
  mounted() {},
  methods: {
    getBg() {
      if (this.isLocal) {
        return require(`../assets/images/${
          this.width <= 768 ? `mobile/${this.mobileBg}` : this.bg
        }`);
      } else {
        return this.bg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ad-header-container {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  .banner-container {
    display: block;
    max-width: 1180px;
    height: 100%;
    box-sizing: border-box;
    margin: 0px auto;
  }
}
.ad-header-container-mobile {
  display: none;
}
@media (max-width: 768px) {
  .ad-header-container {
    display: none !important;
  }
  .ad-header-container-mobile {
    display: block !important;
    width: 100%;
    position: relative;
    .banner-container {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0px auto;
    }
  }
}
</style>