import AdFooterBaseComponent from "./footer.vue";
import AdheaderBaseComponent from "./header.vue"
import AdNavsBaseComponent from "./navs.vue"
import AdFloatDownloadBaseComponent from "./floatDownload.vue"

const components = [
    AdFooterBaseComponent,
    AdheaderBaseComponent,
    AdNavsBaseComponent,
    AdFloatDownloadBaseComponent
];


export default {
    install(Vue) {
        components.map(component => {
            Vue.component(component.name.replace("BaseComponent", ""), component)
        })
    }
}