<template>
  <div class="ad-float-download-base-component" :style="{ top: `${top}px` }">
    <img src="../assets/images/icon-download.png" />
    <p>手机扫码下载</p>
  </div>
</template>

<script>
export default {
  name: "AdFloatDownloadBaseComponent",
  data() {
    return {
      top: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.top = window.innerHeight - 160;
    });
  },
};
</script>

<style lang="scss" scoped>
.ad-float-download-base-component {
  width: 120px;
  height: 144px;
  background: #ffffff;
  box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  border: 1px solid #e6e6e6;
  position: fixed;
  right: 20px;
  z-index: 1500;
  text-align: center;
  box-sizing: border-box;
  img {
    width: 100px;
    height: 100px;
    margin-top: 8px;
  }
  p {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
  }
}
@media screen and (max-width: 768px) {
  .ad-float-download-base-component {
    display: none !important;
  }
}
</style>