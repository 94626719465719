import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        redirect: '/home',
        meta: {
            title: '灵鸽-通过猎头找工作'
        }
    },
    {
        path: '/home',
        name: '首页',
        component: () =>
            import ('@/modules/home.vue'),
        meta: {
            // title: '灵鸽-通过猎头找工作'
        }
    },
    {
        path: '/enterprise',
        name: '首页',
        component: () =>
            import ('@/modules/enterprise.vue'),
        meta: {
            // title: '灵鸽-通过猎头找工作'
        }
    },
    {
        path: '/headhunting',
        name: '首页',
        component: () =>
            import ('@/modules/headhunting.vue'),
        meta: {
            // title: '灵鸽-通过猎头找工作'
        }
    },
    {
        path: '/about',
        name: '关于我们',
        component: () =>
            import ('@/modules/about.vue'),
        meta: {
            title: '灵鸽-关于我们'
        }
    },
    {
        path: '/contact',
        name: '联系我们',
        component: () =>
            import ('@/modules/contact.vue'),
        meta: {
            title: '灵鸽-联系我们'
        }
    },
    {
        path: '/tos/protocol',
        name: '网页服务协议',
        component: () =>
            import ('@/modules/protocol.vue'),
        meta: {
            title: '灵鸽-网页服务协议'
        }
    },
    {
        path: '/tos/agreement',
        name: '用户试用协议',
        component: () =>
            import ('@/modules/agreement.vue'),
        meta: {
            title: '灵鸽-用户试用协议'
        }
    },
    {
        path: '/tos/privacy',
        name: '隐私协议',
        component: () =>
            import ('@/modules/privacy.vue'),
        meta: {
            title: '灵鸽-隐私协议'
        }
    },
    {
        path: '/tos/hunting',
        name: '猎头协议',
        component: () =>
            import ('@/modules/hunting.vue'),
        meta: {
            title: '灵鸽-猎头协议'
        }
    },
    {
        path: '/tos/qualifications',
        name: '经营资质',
        component: () =>
            import ('@/modules/qualifications.vue'),
        meta: {
            title: '灵鸽-经营资质'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    const title = to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
})

export default router;
