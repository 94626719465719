<template>
  <div>
    <div
      :class="[
        'nav-container',
        fixedValue ? 'fixed' : '',
        !fixed ? 'fixed' : '',
      ]"
    >
      <div class="nav">
        <div class="logo">
          <img
            v-if="fixed"
            :src="
              require(`../assets/images/${
                fixedValue ? 'icon_logo_2' : 'icon_logo_1'
              }.png`)
            "
            @click="goHome()"
          />
          <img
            v-if="!fixed"
            :src="require(`../assets/images/icon_logo_2.png`)"
            @click="goHome()"
          />
        </div>
        <div class="menu-tab" @click="changeTab">
          <div data-value='1' :class="{'current': currentTab == 1}">我要换工作</div>
          <div data-value='2' :class="{'current': currentTab == 2}">我要招人</div>
          <div data-value='3' :class="{'current': currentTab == 3}">我要做单</div>
        </div>
        <div class="menu">
          <!-- <el-button
            :class="[type === 'zp' ? '' : 'el-zp']"
            :type="type === 'zp' ? 'primary' : ''"
            @click="toPath('qy')"
          >
            我是招聘方
          </el-button>
          <el-button
            :class="[type === 'lt' ? '' : 'el-zp']"
            :type="type === 'lt' ? 'primary' : ''"
            @click="toPath('lt')"
          >
            我是猎头
          </el-button> -->
          <div class="btn-wrap">
            <el-button
              v-show="currentTab != '1'"
              :type="fixed ? 'primary' : ''"
              @click="toPath"
            >
              登录/注册
            </el-button>
          </div>
        </div>
      </div>
      <div class="nav nav-mobile">
        <div class="logo">
          <img
            v-if="fixed"
            :src="
              require(`../assets/images/mobile/${
                fixedValue ? 'logo_hover' : 'logo'
              }.png`)
            "
            @click="goHome()"
          />

          <img
            v-if="!fixed"
            :src="require(`../assets/images/mobile/logo_hover.png`)"
            @click="goHome()"
          />
        </div>
        <div class="menu">
          <img
            v-if="fixed"
            @click="show = true"
            :src="
              require(`../assets/images/mobile/${
                fixedValue ? 'menu-hover' : 'menu'
              }.png`)
            "
          />
          <img
            v-if="!fixed"
            @click="show = true"
            :src="require(`../assets/images/mobile/menu-hover.png`)"
          />
        </div>
      </div>
    </div>
    <div class="menu-list" v-if="show">
      <div class="company-info">
        <div class="logo">
          <img @click="goHome()" src="../assets/images/mobile/logo.png" />
        </div>
        <div class="menu">
          <img @click="show = false" src="../assets/images/mobile/close.png" />
        </div>
      </div>
      <div class="navs">
        <span></span>
        <a href="/about">关于我们</a>
        <span></span>
        <a href="/tos/privacy">隐私政策</a>
        <span></span>
        <a href="/tos/agreement">用户使用协议</a>
        <span></span>
        <a href="/tos/hunting">猎头协议</a>
        <span></span>
        <a href="/contact">联系我们</a>
        <span></span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AdNavsBaseComponent",
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fixedValue: false,
      show: false,
      currentTab: 1,
    };
  },
  mounted() {
    if (this.fixed) {
      window.addEventListener("scroll", this.fixedActiveBtn);

      this.$nextTick(() => {
        this.fixedActiveBtn();
      });
    }
  },
  methods: {
    changeTab(e) {
      let val = e.target.getAttribute('data-value');
      this.currentTab = val;

    },
    fixedActiveBtn() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.fixedValue = scrollTop > 0;
    },
    toPath() {
      if(this.currentTab == 2) { // 企业
          window.location.href = 'https://co.ringle.com/login'
      } else if (this.currentTab == 3) { //猎头
          window.location.href = 'https://resume.ringle.com/login'
      }
      // if (type === "qy") {
      //   window.location.href = "https://co.ringle.com/login";
      // } else {
      //   window.location.href = "https://resume.ringle.com/login";
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-container {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  color: rgba(255,255,255,0.6500);
  &.fixed {
    color: #222;
    background: #fff;
    .nav {
      .menu-tab {
        color: #222;
         .current {
            color: #222;
          }
      }
      .menu {
        .el-button {
          font-size: 14px;
          font-weight: 400;
          color: #222;
          background-color: #fff;
          border: 1px solid #222 !important;
        }
        .el-zp {
          
          &:hover {
            background: #0083e6;
            color: #fff;
            border: none !important;
          }
        }
      }
    }
  }
  .btn-wrap {
    width: 116px;
  }
  .nav {
    max-width: 1180px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1003;
    .menu-tab {
      cursor: pointer;
      width: 430px;
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      color: rgba(255,255,255,0.6500);
      >div{
        flex: 1;
        text-align: center;
      }
      .current {
        color: #fff;
        position: relative;
        &::after {
          content: '';
          height: 4px;
          width: 34px;
          position: absolute;
          bottom: -24px;
          left: 39%;
          background: #0088FF;
        }
      }
    }
    .logo {
      // flex: 1;
      img {
        width: 66px;
        height: 30px;
        cursor: pointer;
      }
    }
    .menu {
      .el-button {
        font-size: 14px;
        font-weight: 400;
        width: 116px;
        padding: 13px 15px;
        border-radius: 6px;
        border: none !important;
      }
      .el-zp {
        background: rgba(255, 255, 255, 0.2);

        color: #ffffff;

        &:hover {
          background: #0083e6;
        }
      }
    }
  }
  .nav-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .menu-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: #000;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 0px 0px;
    z-index: 90000 !important;
    .company-info {
      height: 50px;
      display: flex;
      align-items: center;
      .logo {
        flex: 1;
        img {
          width: 24px;
          height: 24px;
          margin-left: 20px;
        }
      }
      .menu {
        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }
    }
    .navs {
      display: block !important;
      margin-top: 28px;
      padding: 0px 20px;
      a {
        display: block;
        font-size: 13px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #ffffff;
        text-decoration: none;
        margin: 18px 0px;
      }
      span {
        width: 100%;
        height: 1px;
        display: block;
        border-top: 1px solid #494e5e;
      }
    }
  }
  .nav-container {
    height: 50px;

    .nav {
      width: 100% !important;
      max-width: 100% !important;
      display: none;
    }
    .nav-mobile {
      display: flex;
      .logo {
        img {
          width: 24px;
          height: 24px;
          margin-left: 20px;
        }
      }
      .menu {
        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>