<template>
  <div class="footer-container">
    <div class="footer">
      <div class="foo-left">
        <div class="company-info">
          <img @click="goHome()" src="../assets/images/logo.png" />
          <div class="line"></div>
          <div class="company">
            <p class="cn">深圳市云歌人工智能技术有限公司</p>
            <p class="en">Shenzhen Ringle.AI Technology Co., Ltd.</p>
          </div>
        </div>
        <div class="nav">
          <a href="/about">关于我们</a><span>|</span
          ><a href="/tos/privacy">隐私政策</a><span>|</span
          ><a href="/tos/agreement">用户使用协议</a><span>|</span
          ><a href="/tos/hunting">猎头协议</a><span>|</span
          ><a href="/contact">联系我们</a>
        </div>
      </div>
      <div class="foo-right">
        <div class="wx-item">
          <img src="../assets/images/icon-wx1.png" />
          <p>官网公众号</p>
        </div>
        <div class="wx-item">
          <img src="../assets/images/icon-wx2.png" />
          <p>用户咨询</p>
        </div>
        <div class="wx-item">
          <img src="../assets/images/icon-wx3.png" />
          <p>灵鸽小程序</p>
        </div>
      </div>
    </div>
    <div class="foo-beian">
      <div class="beian">
        <span @click="onFooBeiAnOne()">粤ICP备18045851号-13</span>
        <span @click="onFooBeiAnTwo()">粤公网安备44030502007426</span>
        <span @click="goZZ()">经营资质</span>
      </div>
    </div>
    <div class="footer-mobile">
      <div class="company-info">
        <img @click="goHome()" src="../assets/images/mobile/f-logo.png" />
        <div class="line"></div>
        <div class="company">
          <p class="cn">深圳市云歌人工智能技术有限公司</p>
          <p class="en">Shenzhen Ringle.AI Technology Co., Ltd.</p>
        </div>
      </div>
      <div class="nav">
        <span></span>
        <a href="/about">关于我们</a>
        <span></span>
        <a href="/tos/privacy">隐私政策</a>
        <span></span>
        <a href="/tos/agreement">用户使用协议</a>
        <span></span>
        <a href="/tos/hunting">猎头协议</a>
        <span></span>
        <a href="/contact">联系我们</a>
        <span></span>
      </div>
      <div class="f-w">
        <img src="../assets/images/mobile/w1.png" />
        <img src="../assets/images/mobile/w2.png" />
        <img src="../assets/images/mobile/w3.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdFooterBaseComponent",
  methods: {
    onFooBeiAnOne() {
      window.open("https://beian.miit.gov.cn");
    },
    onFooBeiAnTwo() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502007426"
      );
    },
    goZZ(){
      this.$router.push({path:"/tos/qualifications"})
    }
  },
};
</script>
<style lang="scss" scoped>
.footer-container {
  width: 100%;
  height: 314px;
  background: #1f2437;
  box-sizing: border-box;
  .footer {
    max-width: 1180px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    .foo-left {
      flex: 1;
      .company-info {
        display: flex;
        align-items: center;
        img {
          width: 116px;
          height: 52px;
          cursor: pointer;
        }
        .line {
          width: 1px;
          height: 46px;
          background: #ffffff;
          opacity: 0.2;
          margin: 0px 30px;
        }
        .company {
          .cn {
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            font-family: Noto Sans SC;
          }
          .en {
            font-size: 11px;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
            font-family: Noto Sans SC;
          }
        }
      }
      .nav {
        margin-top: 40px;
        a {
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          color: #ffffff;
          text-decoration: none;
        }
        span {
          margin: 0px 20px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .foo-right {
      display: flex;
      align-items: center;
      .wx-item {
        margin-right: 60px;
        &:last-child {
          margin-right: 0px;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 7px;
        }
        p {
          font-size: 16px;
          font-family: "font-149-1579839079569" !important;
          font-weight: 400;
          color: #ffffff;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .foo-beian {
    width: 100%;
    height: 50px;
    margin: 0px auto;
    background-color: #161a27;
    .beian {
      max-width: 1180px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      span {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #7f8188;
        cursor: pointer;
        &:hover {
          color: #ffffff;
        }
        &:last-child {
          margin-left: 30px;
        }
      }
    }
  }
  .footer-mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    height: auto !important;
    background: #1f2437;
    box-sizing: border-box;
    .footer {
      display: none;
    }
    .foo-beian {
      display: none;
    }
    .footer-mobile {
      box-sizing: border-box;
      display: block !important;
      padding: 20px;
      .company-info {
        display: flex;
        align-items: center;
        img {
          width: 69px;
          height: 31px;
          cursor: pointer;
        }
        .line {
          width: 1px;
          height: 26px;
          background: #ffffff;
          opacity: 0.2;
          margin: 0px 15px;
        }
        .company {
          .cn {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            font-family: Noto Sans SC;
          }
          .en {
            font-size: 8px;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
            font-family: Noto Sans SC;
          }
        }
      }
      .nav {
        margin-top: 28px;
        a {
          display: block;
          font-size: 13px;
          font-family: Noto Sans SC;
          font-weight: 400;
          color: #ffffff;
          text-decoration: none;
          margin: 18px 0px;
        }
        span {
          width: 100%;
          height: 1px;
          display: block;
          border-top: 1px solid #494e5e;
        }
      }
      .f-w {
        margin-top: 30px;
        img {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
