<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    console.log(process.env);
  },
};
</script>

<style>
#app {
  width: 100%;
}
</style>
