import axios from 'axios'

/** 
 * 超时
 */

axios.defaults.timeout = 1000000

axios.defaults.baseURL = ``

axios.defaults.retry = 4

axios.defaults.retryDelay = 1000

axios.defaults.withCredentials = true

/** 
 * 请求拦截
 */
axios.interceptors.request.use(
        async config => {
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )
    /** 
     * 响应拦截
     */
axios.interceptors.response.use(
    response => {
        console.log(response);
        return response.data;
    },
    error => {
        console.log(error);
        return Promise.reject(error)
    }
)

let HttpService = axios

export default HttpService