import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/scss/index.scss'
import App from './App.vue'
import router from './router/index'
import Components from './components/index'

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import httpService from './ddd/http.service'

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
Vue.use(Components)

Vue.http = Vue.prototype.$http = httpService

Vue.goHome = Vue.prototype.goHome = function() {
    window.location.href = "/";
}

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')